import { Link } from 'react-router-dom';
import "./ServiceButton.scss";

const ServiceButton = ({
  label,
  onClick,
  href,
  variant = "default",
  disabled = false,
  color,
}) => {
  const dynamicClass = color ? `service-button--custom` : '';

  const dynamicStyle = color ? { '--button-bg-color': color } : undefined;

  if (href) {
    return (
      <Link
        to={href}
        className={`service-button ${variant} ${dynamicClass} ${
          disabled ? "disabled" : ""
        }`}
        onClick={disabled ? (e) => e.preventDefault() : undefined}
        style={dynamicStyle} // CSS variables for dynamic color
      >
        {label}
      </Link>
    );
  }

  return (
    <button
      className={`service-button ${variant} ${dynamicClass} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClick}
      disabled={disabled}
      style={dynamicStyle} // CSS variables for dynamic color
    >
      {label}
    </button>
  );
};

export default ServiceButton;
