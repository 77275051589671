import "./GradientContainer.scss";

export default function GradientContainer({ children }) {
    return (
      <div className="gradient-container">
       <ul class="squares">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
        {children}
      </div>
    );
  }