import "./MarketingCollateralPage.scss";
import React from "react";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import marketingExample from "../../assets/images/tradeshow-banner-landscape-ontario.jpg";
import catalogueImage from "../../assets/images/beech-nursery-price-list-printed-catalogue.png";
import CaseStudy from "../../components/CaseStudy/CaseStudy";
import RecentWorkCarousel from "../../components/RecentWorkCarousel/RecentWorkCarousel";
import ContactMe from "../../components/ContactMe/ContactMe";


const MarketingCollateralPage = () => {
    return (
      <div className="marketing-collateral-page">
        <ServiceSection
          title="Marketing Collateral"
          description="Let your brand shine and clearly communicate what you offer."
          subtitle="REASONS FOR A MARKETING COLLATERAL"
          bullets={
            <>
              <strong>You're selling a product</strong>
              <br />
              If people don't fully understand what you're offering, it's time to bridge that gap. Client-facing sheets—whether digital or printed—provide a clear, concise overview of your product. They help potential clients understand exactly what you're offering and why it matters, driving engagement and conversions.
              <br /><br />
              <strong>A trade show is coming up</strong>
              <br />
              Banners, booth designs, and props are essential to preparing for a trade show. These elements create a professional, cohesive presence and help ensure your brand stands out from the competition.
            </>
          }
           buttonLabel="Get started now"
        link="/contact"
          image={marketingExample}
        />
       <CaseStudy
        title="Case Study: Product Catalogue Design"
        description={
          <>
              For this project, I designed a product catalogue for print, which was then converted into a digital flipbook for easy online viewing. This dual approach allowed the brand to reach both traditional and modern audiences, providing a versatile way to showcase their products.
              <br /> <br />
              Despite the rise of digital marketing, many audiences still appreciate the tactile, intimate experience of flipping through a printed catalogue. It creates a memorable connection that online viewing alone can't replicate.
              <br /> <br />
              Catalogues are a powerful tool—whether in print or digital format. They give your customers a tangible, engaging way to explore your offerings, making your products stand out and leaving a lasting impression. 
          </>
        }
        image={catalogueImage}
      />
        <RecentWorkCarousel />
        <ContactMe />
      </div>
    );
  };
  
  export default MarketingCollateralPage;