import "./PortfolioPage.scss";
import React from "react";
import PortfolioGrid from "../../components/PortfolioGrid/PortfolioGrid";
import ContactMe from "../../components/ContactMe/ContactMe";


export default function PortfolioPage() {
  return (
    <div className="portfolio-page">
    <div className="portfolio-page__portfolio">
   <h1>Portfolio</h1>
   
<PortfolioGrid />
</div>
<ContactMe />

     </div>
  );
}
