import React from "react";
import "./RecentWorkCarousel.scss"; // Import your stylesheet
import project1Image from "../../assets/images/logo-design-beech-nurseries-l-a-media.png";
import project2Image from "../../assets/images/infographic-tech-cloud-design.jpg";
import project3Image from "../../assets/images/logo-design-lake-erie-studios-l-a-media.png";
import project4Image from "../../assets/images/beech-nursery-price-list-printed-catalogue-front-and-back.jpg";
import project5Image from "../../assets/images/print-design-vinyl-banner-job-fair-garden-nursery.jpg";
import project6Image from "../../assets/images/hall-tree-spading-billboard-la-design.jpg";
import project7Image from "../../assets/images/hall-tree-spading-brochure-print-graphic-design.jpg";
import project8Image from "../../assets/images/billboard-garden-nursery-toronto-ontario.jpg";
import project9Image from "../../assets/images/puma-retouched-photoshop-post-production.gif";

const RecentWorkCarousel = () => {
  const projects = [
    {
      id: 1,
      image: project1Image,
      title: 'Beech Nurseries',
      description: 'Logo Rebrand',
    },
    {
      id: 2,
      image: project2Image,
      title: 'Aleph',
      description: 'Infographic',
    },
    {
      id: 3,
      image: project3Image,
      title: 'Lake Erie Studios',
      description: 'Logo Design for a recording studio',
    },
    {
      id: 4,
      image: project4Image,
      title: 'Beech Nursery West',
      description: 'Printed Catalogue',
    },
    {
      id: 5,
      image: project5Image,
      title: 'Beech Nursery Group',
      description: 'Vinyl - Print Design',
    },
    {
      id: 6,
      image: project6Image,
      title: 'Hall Tree Spading',
      description: 'Billboard - Print Design',
    },
    {
      id: 7,
      image: project7Image,
      title: 'Hall Tree Spading',
      description: 'Brochure - Print Design',
    },
    {
      id: 8,
      image: project8Image,
      title: 'Beech Nursery Garden Centre',
      description: 'Wayfinding Billboard',
    },
    {
      id: 9,
      image: project9Image,
      title: 'Fashion Brand',
      description: 'High-end retouching services',
    },
    // Add more projects as needed
  ];

  return (
    <div className="recent-work-carousel-container">
         <h2>Recent Work</h2>
         <div className="recent-work-carousel">
      {projects.map(project => (
        <div className="portfolio-item" key={project.id}>
          <div className="image-container">
          <img 
              src={project.image} 
              alt={`${project.title} - ${project.description}`} 
            />
          </div>
          <div className="text-container">
            <h6>{project.title}</h6>
            <h5>{project.description}</h5>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};



export default RecentWorkCarousel;