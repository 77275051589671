import "./ServiceCard.scss";
import LearnMoreButton from "../LearnMoreButton/LearnMoreButton";


const ServiceCard = ({ title, description, image, altText, buttonLabel, link }) => {
  return (
    <div className="service-card">
      <div className="service-card__image">
        <img src={image} alt={altText} />
      </div>
      <div className="service-card__content">
        <h2 className="service-card__heading">{title}</h2>
        <h5 className="service-card__description">{description}</h5>
        <LearnMoreButton
          label={buttonLabel}
          href={link}
          variant="default"
        />
      </div>
    </div>
  );
};

export default ServiceCard;
