import "./ContactMeButton.scss";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const ContactMeButton = ({
  label,
  onClick,
  href,
  variant = "default",
  disabled = false,
}) => {
  if (href) {
    // If href starts with "/", use Link from React Router for internal navigation
    if (href.startsWith("/")) {
      return (
        <Link
          to={href} // Use to prop from Link component
          className={`contact-me-button ${variant} ${disabled ? "disabled" : ""}`}
          onClick={disabled ? (e) => e.preventDefault() : undefined}
        >
          {label}
        </Link>
      );
    }

    // If href is an external link or mailto, use regular anchor tag
    return (
      <a
        className={`contact-me-button ${variant} ${disabled ? "disabled" : ""}`}
        href={href}
        onClick={disabled ? (e) => e.preventDefault() : undefined}
        target={href.startsWith("mailto:") ? "_self" : "_blank"}
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }

  return (
    <button
      className={`contact-me-button ${variant} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default ContactMeButton;
