import "./HomePage.scss";
import React from "react";
import GradientContainer from "../../components/GradientContainer/GradientContainer";
import ServiceButton from "../../components/ServiceButton/ServiceButton";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import logoDesignImage from "../../assets/images/graphic-designer-professional-services.svg";
import adDesignImage from "../../assets/images/billboard-downtown-toronto-ad.svg";
import digitalDesignImage from "../../assets/images/digital-design-man-using-uber-app-iphone.svg";
import marketingImage from "../../assets/images/marketing-collateral-client-brochure.svg";
import ContactMe from "../../components/ContactMe/ContactMe";
import RecentWorkCarousel from "../../components/RecentWorkCarousel/RecentWorkCarousel";


export default function HomePage() {
  return (
    <div className="home-page">
     <GradientContainer>
        <div className="home-page__heading-container">
          <h1>Design you can rely on</h1>
          <h3>
            Partnering with marketing managers and business owners to deliver
            design and marketing solutions that drive results.
          </h3>
        

       {/* Button Container */}
       <div className="home-page__button-container">
          <ServiceButton 
            label="Logo Design"
            href="/services/logo-design" // Link to the web design service page
            variant="default"
            color="#fcfcfc"
          />
          <ServiceButton 
            label="Ad Design"
            href="/services/ad-design" // Link to the branding service page
            variant="default"
            color="#fcfcfc"
          />
          <ServiceButton 
            label="Digital Design"
            href="/services/digital-design" // Link to the marketing service page
            variant="default"
            color="#fcfcfc"
          />
          <ServiceButton 
            label="Marketing Collateral"
            href="/services/marketing-collateral" // Link to the consultation service page
            variant="default"
            color="#fcfcfc"
          />
        </div>
        </div>

       {/* Service Cards Container */}
<div className="home-page__service-cards-container">
  <div className="home-page__service-cards">
    <ServiceCard
      title="Logo Design"
      description="Get a premium, vector-based logo designed to elevate your brand across digital platforms and marketing materials."
      image={logoDesignImage}
      alt="Lindsay Adams designing a logo"
      buttonLabel="Why a Logo Matters"
      link="/services/logo-design"
    />
  </div>
  <div className="home-page__service-cards">
    <ServiceCard
      title="Ad Design"
      description="Stand out with professional, customized ad designs for both print and digital platforms."
      image={adDesignImage}
      alt="Billboard ad design in Toronto"
      buttonLabel="How Ads Drive Leads"
      link="/services/ad-design"
    />
  </div>
  <div className="home-page__service-cards">
    <ServiceCard
      title="Digital Design"
      description="Stunning digital designs tailored for social media, landing pages, and other web-centric platforms."
      image={digitalDesignImage}
      alt="Man using a ride-sharing app on a smartphone"
      buttonLabel="Boost Your Digital Designs"
      link="/services/digital-design"
    />
  </div>
  <div className="home-page__service-cards">
    <ServiceCard
      title="Marketing Collateral"
      description="Effective marketing materials, including client-facing sheets, sell sheets, and tradeshow displays to grow your business."
      image={marketingImage}
      alt="Client reading a marketing brochure"
      buttonLabel="Build Strong Marketing Assets"
      link="/services/marketing-collateral"
    />
  </div>
</div>

      </GradientContainer>
      <ContactMe />
      <RecentWorkCarousel />


     </div>
  );
}
