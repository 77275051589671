import "./ContactMe.scss";
import ContactMeButton from "../../components/ContactMeButton/ContactMeButton";
import EmailMeButton from "../../components/EmailMeButton/EmailMeButton";

export default function ContactMe() {
  return (
    <div className="contact-me">
      <h2>Dependable, Confident, and Ready to Bring Your Vision to Life</h2>
          <h5>
          My name is Lindsay, and I specialize in versatile graphic design solutions tailored to your needs. With expertise in print production and digital media, I deliver high-quality results you can rely on—on time, every time.
          </h5>

           {/* Button Container */}
       <div className="contact-me__button-container">
          <ContactMeButton 
            label="Contact me"
            href="/contact"
            variant="default" 
          />
          <div>
      <EmailMeButton
      email="lindsayadamsdesign@gmail.com"
      textColor="white"
      iconColor="white"
      />
    </div>
        </div>
    </div>
  );
}