import "./ContactMePage.scss";
import React from "react";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import logoDesignImage from "../../assets/images/graphic-designer-professional-services.svg";
import EmailMeButton from "../../components/EmailMeButton/EmailMeButton";

const ContactMePage = () => {
  return (
    <div className="contact-me-page">
      <ServiceSection
        title="Contact Me"
        description="Lindsay Adams"
        subtitle="PARTNER WITH ME TODAY"
        bullets={
          <>
          I’m available for appointments at the address below:
          <br /> <br />
          135 Brant Avenue
          <br />
          Brantford, Ontario, N3T 3H5
          <br /> <br />
          Call me:
          <br />
            <a href="tel:2269209764" className="contact-link">
              226.920.9764
            </a>
            <br /> <br />
            or
            <br /> <br />
            <EmailMeButton
      email="lindsayadamsdesign@gmail.com"
      textColor="#A8631D"
      iconColor="#A8631D"
      />
          </>
          
        }
        
        image={logoDesignImage}
      />
    </div>
  );
};

export default ContactMePage;
