import "./DigitalDesignPage.scss";
import React from "react";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import digitalExample from "../../assets/images/digital-app-design-ux.png";
// import CaseStudy from "../../components/CaseStudy/CaseStudy";
import RecentWorkCarousel from "../../components/RecentWorkCarousel/RecentWorkCarousel";
import ContactMe from "../../components/ContactMe/ContactMe";


const DigitalDesignPage = () => {
    return (
      <div className="digital-design-page">
        <ServiceSection
          title="Digital Design"
          description="User-centered design across the tech landscape."
          subtitle="WHY INVEST IN DIGITAL DESIGN"
          bullets={
            <>
              <strong>A strong landing page</strong>
              <br />
              When a prospective client lands on your website, the user experience must be seamless. A well-designed landing page ensures visitors can easily navigate your site and engage with your content. This ease of navigation plays a crucial role in keeping your audience interested and encouraging them to explore further.
              <br /><br />
              <strong>The digital world is competitive</strong>
              <br />
              With millions of potential clients browsing online, your website must stand out. Professional images, compelling copy, and responsive design are key to capturing your audience's attention. Your website should adapt perfectly across all devices to ensure a smooth, engaging experience.
              <br /><br />
              <strong>You need an app designed for Android and iPhone</strong>
              <br />
              Designing for mobile requires expertise, especially since Android and iPhone interfaces are distinct. Each platform has its own design guidelines, user expectations, and technical specifications. Only an experienced designer can create an app that feels native and functions flawlessly across both platforms, ensuring a great experience for all users.
            </>
          }
           buttonLabel="Get started now"
        link="/contact"
          image={digitalExample}
        />
       {/* <CaseStudy
        title="Case Study: Email Marketing Design"
        description={
          <>
              coming soon
           
          </>
        }
      /> */}
        <RecentWorkCarousel />
        <ContactMe />
      </div>
    );
  };
  
  export default DigitalDesignPage;