import React, { useEffect } from "react";
import "./CaseStudy.scss";

const CaseStudy = ({ title, description, image }) => {
  useEffect(() => {
    // Select the image inside the case-study section
    const imageElement = document.querySelector(".case-study__image img");

    // Initialize the Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = "slideUp 1s ease-out forwards";
          observer.unobserve(entry.target); // Stop observing after animating
        }
      });
    });

    // Observe the image element
    if (imageElement) observer.observe(imageElement);

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);

  return (
    <section className="case-study">
      <div className="case-study__content">
        <div className="case-study__text">
          <h2>{title}</h2>
          <h5>{description}</h5>
        </div>
        <div className="case-study__image">
          <img src={image} alt={title} />
        </div>
      </div>
    </section>
  );
};

export default CaseStudy;
