import "./LogoDesignPage.scss";
import React from "react";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import logoExample from "../../assets/images/logo-design-studio-brant.png";
import milestoneLogo from "../../assets/images/milestone-logo-yeti-cropped.png";
import CaseStudy from "../../components/CaseStudy/CaseStudy";
import RecentWorkCarousel from "../../components/RecentWorkCarousel/RecentWorkCarousel";
import ContactMe from "../../components/ContactMe/ContactMe";


const LogoDesignPage = () => {
    return (
      <div className="logo-design-page">
        <ServiceSection
          title="Logo Design"
          description="Perfect for scalability, consistency, and a professional look that stands out."
          subtitle="REASONS FOR A PROFESSIONAL LOGO"
          bullets={
            <>
              <strong>Brand refresh</strong>
              <br />
              As your brand evolves, your logo should reflect that change. If your current logo no longer represents where your business is today, it may be time for an update.
              <br /><br />
              <strong>You're starting a new business</strong>
              <br />
              A logo is key to creating a professional identity. It helps you stand out and build recognition, making it easier for customers to remember you and attract new ones.
              <br /><br />
              <strong>Your business has an anniversary</strong>
              <br />
              Anniversaries are milestones that highlight the longevity of your business. A custom anniversary logo not only helps celebrate your success but also demonstrates your enduring presence to customers and prospects.
            </>
          }
           buttonLabel="Get started now"
        link="/contact"
          image={logoExample}
        />
       <CaseStudy
        title="Case Study: Milestone Logo"
        description={
          <>
            I designed a custom 45th-anniversary logo for Aquaman Irrigation, incorporating colors and elements from their existing brand identity. This highly personalized logo seamlessly connects with their history while celebrating their milestone.
              <br /> <br />
              The logo works beautifully across various platforms—whether it’s featured in advertisements, on company websites, social media platforms,  business cards, or other marketing materials.
              <br /> <br />
              Show off your milestone in style—on a YETI!
           </>
        }
        image={milestoneLogo}
      />
        <RecentWorkCarousel />
        <ContactMe />
      </div>
    );
  };
  
  export default LogoDesignPage;