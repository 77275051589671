import "./AdDesignPage.scss";
import React from "react";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import adExample from "../../assets/images/l-a-media-design-social-media-banner.jpg";
import billboard from "../../assets/images/graphic-design-billboard-garden-nursery.png";
import CaseStudy from "../../components/CaseStudy/CaseStudy";
import RecentWorkCarousel from "../../components/RecentWorkCarousel/RecentWorkCarousel";
import ContactMe from "../../components/ContactMe/ContactMe";


const AdDesignPage = () => {
    return (
      <div className="ad-design-page">
        <ServiceSection
          title="Ad Design"
          description="Show off your brand and generate leads."
          subtitle="  WHY INVEST IN PROFESSIONAL AD DESIGN"
          bullets={
            <>
              <strong>Your potential clients are online</strong>
              <br />
              Running a digital marketing campaign can reach thousands of potential leads. Professionally designed ads with compelling copy will attract the audience you want and deliver a strong return on investment (ROI).
              <br /><br />
              <strong>Industry-specific magazines</strong>
              <br />
              Advertising in industry-specific magazines is a smart move—your audience is actively reading them. A professionally designed ad ensures your brand stands out and gets noticed by the right people.
              <br /><br />
              <strong>Public recognition</strong>
              <br />
              Local advertising, such as billboards, puts your brand front and center in your community. It's an effective way to connect with potential customers right in your neighborhood.
            </>
          }
          buttonLabel="Get started now"
        link="/contact"
          image={adExample}
        />
       <CaseStudy
        title="Case Study: Wayfinding Billboard"
        description={
          <>
          <strong>Challenge</strong>
          <br />
A garden nursery faced a unique challenge: their location was tucked away in an awkward part of the city, making it difficult for potential customers to find them. They needed a bold and effective way to guide people to their store.
<br /><br />
<strong>Solution</strong>
<br />
I created a sleek and straightforward billboard design that prominently showcased their brand and name. The design was optimized for visibility, ensuring it could be easily read from a distance. Using high-quality graphics and a professional layout, the billboard not only served its purpose of wayfinding but also reinforced the nursery’s stylish and trustworthy image.
            
          </>
        }
        image={billboard}
      />
        <RecentWorkCarousel />
        <ContactMe />
      </div>
    );
  };
  
  export default AdDesignPage;