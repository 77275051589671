import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";

import usePageTracking from "./usePageTracking";
import ScrollToTop from './components/ScrollToTop'; 

import Header from "./components/Header/Header";
import HomePage from "./pages/HomePage/HomePage";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import ContactMePage from "./pages/ContactMePage/ContactMePage";
import LogoDesignPage from "./pages/LogoDesignPage/LogoDesignPage";
import AdDesignPage from "./pages/AdDesignPage/AdDesignPage";
import DigitalDesignPage from "./pages/DigitalDesignPage/DigitalDesignPage";
import MarketingCollateralPage from "./pages/MarketingCollateralPage/MarketingCollateralPage";
import Footer from "./components/Footer/Footer";

function App() {
  // Call the custom hook to track page views
  usePageTracking();
  const location = useLocation();

  return (
    <div className="app">
      <Header className="app__header" />
      <ScrollToTop /> {/* Keep ScrollToTop here */}
      <Routes location={location} key={location.key}> {/* Add key prop to Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/services/logo-design" element={<LogoDesignPage />} />
        <Route path="/services/ad-design" element={<AdDesignPage />} /> 
        <Route path="/services/digital-design" element={<DigitalDesignPage />} /> 
        <Route path="/services/marketing-collateral" element={<MarketingCollateralPage />} /> 
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/contact" element={<ContactMePage />} /> 
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
