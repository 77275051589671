import "./LearnMoreButton.scss";
import { Link } from 'react-router-dom';

const LearnMoreButton = ({
  label,
  onClick,
  href,
  variant = "default",
  disabled = false,
}) => {
  const arrow = <span className="arrow" />; // Add the arrow as a span element

  if (href) {
    if (href.startsWith("/")) {
      return (
        <Link
          to={href}
          className={`learn-more-button ${variant} ${disabled ? "disabled" : ""}`}
          onClick={disabled ? (e) => e.preventDefault() : undefined}
        >
          {label} {arrow}
        </Link>
      );
    }

    return (
      <a
        className={`learn-more-button ${variant} ${disabled ? "disabled" : ""}`}
        href={href}
        onClick={disabled ? (e) => e.preventDefault() : undefined}
        target={href.startsWith("mailto:") ? "_self" : "_blank"}
        rel="noopener noreferrer"
      >
        {label} {arrow}
      </a>
    );
  }

  return (
    <button
      className={`learn-more-button ${variant} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label} {arrow}
    </button>
  );
};

export default LearnMoreButton;
