import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);  // Scroll to top after a brief delay
    }, 100);
    
    return () => clearTimeout(timer);  // Clean up timeout on component unmount
  }, [location]);

  return null;
};

export default ScrollToTop;
