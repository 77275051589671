import React from "react";
import "./ServiceSection.scss"; // Import your stylesheet
import LearnMoreButton from "../../components/LearnMoreButton/LearnMoreButton";

const ServiceSection = ({ title, description, subtitle, bullets, buttonLabel, link, image }) => {
  return (
    <section className="service-section">
      <div className="service-section__content">
        <div className="service-section__text">
          <h1>{title}</h1>
          <h2>{description}</h2>
          <h6>{subtitle}</h6>
          <h5>{bullets}</h5>
          {/* Render the button only if buttonLabel and link are provided */}
          {buttonLabel && link && (
           <div className="service-section__button">
            <LearnMoreButton
              label={buttonLabel}
              href={link}
              variant="default"
            />
            </div>
          )}
        </div>
        <div className="service-section__image">
          <img src={image} alt={title} />
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
